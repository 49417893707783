import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './SelectSchools.css';
import Berkeley from './Assets/Berkeley.jpg';
import Chicago from './Assets/Chicago.jpg';
import Columbia from './Assets/Columbia.jpg';
import Cornell from './Assets/Cornell.jpg';
import Dartmouth from './Assets/Dartmouth.jpg';
import Duke from './Assets/Duke.jpg';
import Harvard from './Assets/Harvard.jpg';
import Michigan from './Assets/Michigan.jpg';
import MIT from './Assets/MIT.jpg';
import Northwestern from './Assets/Northwestern.jpg';
import NYU from './Assets/NYU.jpg';
import Penn from './Assets/Penn.jpg';
import Stanford from './Assets/Stanford.jpg';
import UCLA from './Assets/UCLA.jpg';
import Virginia from './Assets/Virginia.jpg';
import Yale from './Assets/Yale.jpg';
import HEC from './Assets/HEC.jpg';
import INSEAD from './Assets/INSEAD.jpg';
import LBS from './Assets/LBS.jpg';
import UNC from './Assets/UNC.jpg';
import USC from './Assets/USC.jpg';
import UnlinkedBanner from './unlinkedBanner.js';

const images = [
  { name: 'Penn', src: Penn },
  { name: 'Harvard', src: Harvard },
  { name: 'Stanford', src: Stanford },
  { name: 'Northwestern', src: Northwestern },
  { name: 'Columbia', src: Columbia },
  { name: 'NYU', src: NYU },
  { name: 'Duke', src: Duke },
  { name: 'Chicago', src: Chicago },
  { name: 'MIT', src: MIT },
  { name: 'Dartmouth', src: Dartmouth },
  { name: 'Michigan', src: Michigan },
  { name: 'UCLA', src: UCLA },
  { name: 'Berkeley', src: Berkeley },
  { name: 'Yale', src: Yale },
  { name: 'Cornell', src: Cornell },
  { name: 'Virginia', src: Virginia },
  { name: 'INSEAD', src: INSEAD },
  { name: 'LBS', src: LBS },
  { name: 'USC', src: USC },
  { name: 'UNC', src: UNC },
  { name: 'HEC', src: HEC },
];

const SelectSchools = () => {
  const [selectedSchools, setSelectedSchools] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const tier = location.state?.tier || 'Individual';
  const userid = location.state?.userid;

  const toggleSchool = (schoolName) => {
    setSelectedSchools(prevSelected => 
      prevSelected.includes(schoolName)
        ? prevSelected.filter(name => name !== schoolName)
        : [...prevSelected, schoolName]
    );
  };

  const handleCheckout = () => {
    navigate('/checkout', { 
      state: { 
        tier, 
        schools: selectedSchools,
        price: selectedSchools.length * 100,
        userid,
      } 
    });
  };

  return (
    <div className="button-grid-container">
      <UnlinkedBanner />
      <div className="button-grid">
        {images.map((image, index) => (
          <button 
            key={index} 
            className={`grid-button ${selectedSchools.includes(image.name) ? 'selected' : ''}`}
            onClick={() => toggleSchool(image.name)}
          >
            <img src={image.src} alt={image.name} />
          </button>
        ))}
      </div>
      <button 
        className="select-schools-checkout-button" 
        onClick={handleCheckout}
        disabled={selectedSchools.length === 0}
      >
        Checkout
      </button>
    </div>
  );
};

export default SelectSchools;
