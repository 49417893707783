import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Columbia.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "Through your resume and recommendation, we have a clear sense of your professional path to date. What are your career goals over the next three to five years and what is your long-term dream job?"
  },
  {
    text: "The Phillips Pathway for Inclusive Leadership (PPIL) is a co-curricular program designed to provide students with the skills and strategies needed to develop as inclusive leaders.  Through various resources and programming, students explore and reflect on the following five inclusive leadership skills:  Mitigating Bias and Prejudice; Managing Intercultural Dialogue; Addressing Systemic Inequity; Understanding Identity and Perspective Taking; and Creating an Inclusive Environment. Describe a time or situation when you had the need to utilize one of these five skills, and tell us the actions you took and the outcome."
  },
  {
    text: "We believe Columbia Business School is a special place with a collaborative learning environment in which students feel a sense of belonging, agency, and partnership--academically, culturally, and professionally. How would you co-create your optimal MBA experience at CBS? Please be specific."
  },
  {
    text: "If you wish to provide further information or additional context around your application to the Admissions Committee, please upload a brief explanation of any areas of concern in your academic record or personal history. This does not need to be a formal essay. You may submit bullet points."
  }
];

const Columbia = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/columbia/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Columbia Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Columbia;