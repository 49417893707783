// Settings.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Settings.css';
import BackButton from './Assets/Back.png'; // Ensure the path is correct

const Settings = () => {
  const navigate = useNavigate();
  // const location = useLocation();
  
  // Retrieve userid from location.state
  // const userid = location.state?.userid;

  // Handle Back Button Click
  const handleBackClick = () => {
    navigate(-1); // Navigates back to the previous page
  };

  // Handle Logout Click
  const handleLogout = () => {
    navigate('/login'); // Navigate to Login.js
  };

  // Handle Change Plan Click
  // const handleChangePlan = () => {
  //   navigate('/tiers', { state: { userid } }); // Navigate to Tiers.js with userid
  // };

  return (
    <div className="settings-page">
      {/* Header */}
      <div className="settings-header">
        <button className="back-button" onClick={handleBackClick}>
          <img src={BackButton} alt="Back" />
        </button>
        <h1 className="settings-title">Settings</h1>
      </div>

      {/* Options */}
      <div className="settings-options">
        <button className="settings-option" onClick={handleLogout}>
          Logout
        </button>
        {/* <button className="settings-option" onClick={handleChangePlan}>
          Change Plan
        </button> */}
      </div>
    </div>
  );
};

export default Settings;