import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Tiers.css';
import UnlinkedBanner from './unlinkedBanner.js';

const Tiers = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleEssayBoostClick = () => {
    navigate('/checkout', { state: { tier: 'General', price: 100, userid } });
  };

  const handleEssayMasteryClick = () => {
    navigate('/select-schools', { state: { userid } });
  };

  return (
    <div className="main-container">
      <UnlinkedBanner />
      <div className="tiers-container">
        <div className="tiers-content">
          <div className="tier-card" onClick={handleEssayBoostClick}>
            <h2>General Assistance</h2>
            <div className="tier-description">
              <p>The General Assistance tier provides personalized help for general essay questions, drawing insights from a database of over 3,500 successful consultant-revised essays. Whether you're working on academic papers, personal statements, or application essays, this service helps you refine your work by offering tailored guidance and constructive feedback.</p>
              <ol>
                <li><strong>Proven Essay Structures:</strong> Gain access to best practices based on thousands of successful essays to help you organize and structure your ideas effectively.</li>
                <li><strong>Expert Feedback:</strong> Receive actionable, customized advice without copying from example essays, ensuring your essay improves while maintaining originality.</li>
                <li><strong>Clarification of Assumptions:</strong> Probing questions help challenge and refine your arguments for precision and clarity.</li>
                <li><strong>Constructive Criticism:</strong> Get in-depth critiques on flow, clarity, and structure, encouraging step-by-step revisions until your essay is polished.</li>
                <li><strong>Guidance for Stronger Writing:</strong> Practical tips for improving everything from thesis statements to transitions, drawn from successful essay models.</li>
              </ol>
              <p>This tier offers a straightforward yet effective way to boost your essay-writing skills, helping you produce clear, well-organized, and persuasive essays.</p>
            </div>
            <div className="price">$100</div>
          </div>
          <div className="tier-card" onClick={handleEssayMasteryClick}>
            <h2>Individual Schools</h2>
            <div className="tier-description">
              <p>The Individual Schools tier offers high-level assistance for crafting essays tailored specifically to the application questions of your chosen schools. This tier provides targeted insights and guidance to help you stand out in competitive application processes.</p>
              <ol>
                <li><strong>School-Specific Expertise</strong> Access essays categorized by specific schools and questions, ensuring that your responses are aligned with the expectations and style preferred by each institution.</li>
                <li><strong>Tailored Feedback for School Applications:</strong> Receive personalized advice on how to refine your essays to fit the unique culture and requirements of each school.</li>
                <li><strong>Question-by-Question Breakdown:</strong> Each essay is approached with detailed attention to the specific prompt, ensuring that your response directly addresses the question with clarity and relevance.</li>
                <li><strong>Polish and Perfect:</strong> Get advanced feedback on your essay’s tone, structure, and content to ensure it is polished and compelling.</li>
                <li><strong>Iterative Revisions:</strong> Continuous support for refining drafts through multiple revisions, helping you improve each aspect of your essay until it’s application-ready.</li>
              </ol>
              <p>This tier provides you with the tools and expert guidance needed to craft essays that reflect your best self while addressing the precise needs of each school’s application.</p>
            </div>
            <div className="price">$100 each</div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Tiers;