import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/HEC.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "Why are you applying to the HEC MBA Program now? What is the professional objective that will guide your career choice after your MBA, and how will the HEC MBA contribute to the achievement of this objective?"
  },
  {
    text: "What do you consider your most significant life achievement?"
  },
  {
    text: "Leadership and ethics are inevitably intertwined in the business world. Describe a situation in which you have dealt with these issues and how they have influenced you."
  },
  {
    text: "Imagine a life entirely different from the one you now lead, what would it be?"
  },
  {
    text: "If there is anything else you would like to share with us that has not already been shared elsewhere in your file, please feel free to use this space."
  }
]

const HEC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/hec/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="HEC Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default HEC;