import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Stanford.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "Essay A: What matters most to you, and why?"
  },
  {
    text: "Essay B: Why Stanford?"
  },
  {
    text: "Think about a time in the last few years when you’ve created a positive impact, whether in professional, extracurricular, academic, or other settings. What was your impact? What made it significant to you or to others?"
  }
]

const Stanford = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/stanford/${encodeURIComponent(question)}`, { state: { userid }});
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Stanford Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Stanford;