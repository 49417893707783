import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Berkeley.jpg';
import Banner from '../Banner.js'

const questions = [
  {
    text: "What makes you feel alive when you are doing it, and why?"
  },
  {
    text: "What are your short-term and long-term career goals, and how will an MBA from Haas help you achieve those goals?"
  },
  {
    text: "Describe any experience or exposure you have in the area of diversity, equity, inclusion, justice, and belonging whether through community organizations, personal, or in the workplace?"
  },
  {
    text: "The Berkeley MBA program develops leaders who embody our four Defining Leadership Principles. Briefly introduce yourself to the admissions committee, explain which Defining Leadership Principle resonates most with you, and tell us how you have exemplified the principle in your personal or professional life. Please review the Defining Leadership Principles in advance and take time to prepare your answer before recording. You will be able to test your audio-visual connection before recording. Video essays should last 1-2 minutes and may not exceed 2 minutes."
  },
  {
    text: "Optional Information #1: We invite you to help us better understand the context of your opportunities and achievements."
  },
  {
    text: "Optional Information #2: This section should only be used to convey relevant information not addressed elsewhere in your application. This may include explanation of employment gaps, academic aberrations, supplemental coursework, etc. You are encouraged to use bullet points where appropriate."
  }
]

const Berkeley = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/berkeley/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
        <img src={Logo} alt="Berkeley Logo" className="school-logo" />
        <h2 className="question-header">Select a question:</h2>
      </div>
      {questions.map((question, index) => (
        <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
          {question.text}
        </button>
      ))}
    </div>
    </div>
  );
};

export default Berkeley;