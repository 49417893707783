import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Cornell.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "Goals Statement: Use this short-answer section to succinctly share your short- and long-term goals. If you’re invited to interview, you will have the opportunity to elaborate on your statement further, and you should be prepared to connect your prior experience with your future aspirations."
  },
  {
    text: "At Cornell, our students and alumni share a desire to positively impact the organizations and communities they serve. How do you intend to make a meaningful impact on the Johnson community?"
  },
  {
    text: "What is something unique about you that others will remember you by?"
  },
  {
    text: "You may use this essay to call attention to items needing clarification and to add additional details to any aspects of your application that do not accurately reflect your potential for success at Johnson."
  }
]

const Cornell = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/cornell/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Cornell Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Cornell;