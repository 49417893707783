import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/USC.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "What is your specific, immediate short-term career goal upon completion of your MBA? Please include an intended position, function, and industry in your response."
  },
  {
    text: "Please draft a letter that begins with “Dear Admissions Committee” This letter serves as your personal statement that provides the Admissions Committee with an understanding of your candidacy for Marshall beyond what is evident in other parts of your application. This essay is purposely open-ended. You are free to express yourself in whatever way you see fit. The goal is to have an appreciation for and an understanding of each candidate in ways that are not captured by test scores, grades, and resumes."
  },
  {
    text: "Please provide any additional information you would like the admissions committee to consider."
  }
]

const USC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/usc/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="USC Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default USC;