import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/NYU.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "What are your short-term career goals?"
  },
  {
    text: "In today’s global business environment, the only constant is change. Using NYU Stern’s brand call to action, we want to know how you view change. Change: _____ it. Fill in the blank with a word of your choice. Why does this word resonate with you? How will you embrace your own personal tagline while at Stern?"
  },
  {
    text: "Introduce yourself to the Admissions Committee and to your future classmates using six images and corresponding captions. The Pick Six is a way to share more about the qualities you will bring to the Stern community, beyond your professional and academic achievements."
  },
  {
    text: "Please provide any additional information that you would like to bring to the attention of the Admissions Committee and/or give context to your application. This may include important aspects of yourself not otherwise apparent in your application, including but not limited to: hardships you have encountered, current or past gaps in employment, further explanation of your undergraduate record or self-reported academic transcript(s), plans to retake the GMAT, GRE, Executive Assessment, IELTS or TOEFL, or any other relevant information."
  }
]

const NYU = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/nyu/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
     <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="NYU Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default NYU;