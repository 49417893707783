import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Harvard.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "Please reflect on how your experiences have influenced your career choices and aspirations and the impact you strive to make on the businesses, organizations, and communities you plan to serve."
  },
  {
    text: "What experiences have shaped who you are, how you invest in others, and what kind of leader you want to become?"
  },
  {
    text: "Curiosity can be seen in many ways. Please share an example of how you have demonstrated curiosity and how that has influenced your growth."
  }
]

const Harvard = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/harvard/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Harvard Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Harvard;