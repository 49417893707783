import React from 'react';
import './unlinkedBanner.css';
import SBCLogo from './Assets/SBC.jpg';

const UnlinkedBanner = () => {
  return (
    <header className="banner">
      <img src={SBCLogo} alt="SBC Logo" className="banner-logo" />
    </header>
  );
};

export default UnlinkedBanner;