import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './Banner.css';
import SBCLogo from './Assets/SBC.jpg';
import Menu from "./Assets/Menu.png";
import Settings from "./Assets/Settings.png";
import Home from "./Assets/Home.png";
import ChatOptions from './Assets/ChatOptions.png';
import supabase from './supabaseClient.js';

const Banner = ({ userid }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [chats, setChats] = useState([]);
  const [activeChatOptions, setActiveChatOptions] = useState(null);
  const navigate = useNavigate();

  const toggleSidebar = () => {
    setIsSidebarOpen(prevState => !prevState);
  };

  useEffect(() => {
    if (isSidebarOpen) {
      document.body.classList.add('sidebar-open');
    } else {
      document.body.classList.remove('sidebar-open');
    }
    return () => {
      document.body.classList.remove('sidebar-open');
    };
  }, [isSidebarOpen]);

  useEffect(() => {
    const fetchChats = async () => {
      if (!userid) return;
      const { data, error } = await supabase
        .from('chats')
        .select('id, school, question, question_code, title')
        .eq('user', userid)
        .order('most_recent_message', { ascending: false });

      if (error) {
        console.error('Error fetching chats:', error.message);
      } else {
        setChats(data);
      }
    };

    fetchChats();
  }, [userid]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        !event.target.closest('.chat-options-menu') &&
        !event.target.closest('.chat-options-button')
      ) {
        setActiveChatOptions(null);
      }
    };

    if (activeChatOptions !== null) {
      document.addEventListener('click', handleClickOutside);
    }

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [activeChatOptions]);

  const handleChatClick = (school, question) => {
    const encodedQuestion = encodeURIComponent(question); // Ensure the question is URL-safe
    navigate(`/chat/${school}/${encodedQuestion}`, { state: { userid } }); // Pass userid via state
    toggleSidebar(); // Close sidebar after navigation
  };

  const handleHomeClick = () => {
    navigate('/button-grid', { state: { userid } }); // Navigate to ButtonGrid with userid
    toggleSidebar(); // Close sidebar after navigation
  };

  const handleRenameChat = async (chatId) => {
    const newTitle = prompt('Enter new chat title:');
    if (newTitle !== null && newTitle.trim() !== '') {
      // Update the chat title in Supabase
      const { error } = await supabase
        .from('chats')
        .update({ title: newTitle.trim() })
        .eq('id', chatId);

      if (error) {
        console.error('Error renaming chat:', error.message);
        alert('Failed to rename chat. Please try again.');
      } else {
        // Update the chat title in the local state
        setChats((prevChats) =>
          prevChats.map((chat) =>
            chat.id === chatId ? { ...chat, title: newTitle.trim() } : chat
          )
        );
      }
    }
    setActiveChatOptions(null);
  };

  const handleDeleteChat = async (chatId) => {
    const confirmDelete = window.confirm(
      'Are you sure you want to delete this chat? This action cannot be undone.'
    );
    if (!confirmDelete) {
      setActiveChatOptions(null);
      return;
    }
    try {
      // Step 1: Retrieve the messages associated with the chat
      const { data: chatData, error: fetchError } = await supabase
        .from('chats')
        .select('messages')
        .eq('id', chatId)
        .single();
  
      if (fetchError) {
        console.error('Error fetching chat messages:', fetchError.message);
        alert('Failed to retrieve chat messages. Please try again.');
        return;
      }
  
      const messageIds = chatData.messages;
  
      if (messageIds && messageIds.length > 0) {
        // Step 2: Delete messages from the 'messages' table
        const { error: deleteMessagesError } = await supabase
          .from('messages')
          .delete()
          .in('id', messageIds);
  
        if (deleteMessagesError) {
          console.error('Error deleting messages:', deleteMessagesError.message);
          alert('Failed to delete associated messages. Please try again.');
          return;
        }
      }
      const { error: deleteChatError } = await supabase
      .from('chats')
      .delete()
      .eq('id', chatId);

      if (deleteChatError) {
        console.error('Error deleting chat:', deleteChatError.message);
        alert('Failed to delete chat. Please try again.');
        return;
      }

      // Step 4: Update the local state to remove the deleted chat
      setChats((prevChats) => prevChats.filter((chat) => chat.id !== chatId));
    } catch (error) {
      console.error('Unexpected error:', error);
      alert('An unexpected error occurred. Please try again.');
    } finally {
      setActiveChatOptions(null);
    }
  };

  return (
    <div className="banner">
      <img
        src={Menu}
        alt="Menu"
        className="menu-button"
        onClick={toggleSidebar}
      />
      <img src={SBCLogo} alt="SBC Logo" className="banner-logo"  onClick={handleHomeClick}/>
      <img 
        src={Settings}
        alt="Settings"
        className="settings-button"
        onClick={() => navigate('/settings', { state: { userid } })}
      />
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
      <div className="sidebar-header">
          <h2>Chats</h2>
          <img
            src={Home}
            alt="Home"
            className="home-icon"
            onClick={handleHomeClick}
          />
        </div>
        <ul className="chat-list">
          {chats.length > 0 ? (
            chats.map(chat => (
              <li key={chat.id} className="chat-list-item">
                <div
                  className="chat-link"
                  onClick={() => handleChatClick(chat.school, chat.question)}
                >
                <span className="chat-title">{chat.title}</span>
                <button
                    className="chat-options-button"
                    onClick={(e) => {
                      e.stopPropagation(); // Prevent click from triggering chat navigation
                      setActiveChatOptions(
                        activeChatOptions === chat.id ? null : chat.id
                      );
                    }}
                  >
                    <img src={ChatOptions} alt="Options" />
                  </button>
                </div>
                {activeChatOptions === chat.id && (
                  <div className="chat-options-menu">
                    <button onClick={() => handleRenameChat(chat.id)}>
                      Rename
                    </button>
                    <button onClick={() => handleDeleteChat(chat.id)}>
                      Delete
                    </button>
                  </div>
                )}
              </li>
            ))
          ) : (
            <li>No chats available.</li>
          )}
        </ul>
      </div>
    </div>
  );
};

export default Banner;