import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import supabase from './supabaseClient.js';
import GeneralBanner from './generalBanner.js';
import * as pdfjsLib from 'pdfjs-dist';
import mammoth from 'mammoth';
import { v4 as uuidv4 } from 'uuid';
import './GeneralTier.css';

import Paperclip from './Assets/Paperclip.png';
import SubmitIcon from './Assets/SubmitIcon.png';
import Logo from './Assets/Logo.jpg'
import Attached from './Assets/Attached.png';

pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.mjs',
  import.meta.url
).toString();

const formatGptResponse = (text) => {
  let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  formattedText = formattedText.replace(/### (.*?)\n/g, '<h3>$1</h3>');
  formattedText = formattedText.replace(/## (.*?)\n/g, '<h2>$1</h2>');
  formattedText = formattedText.replace(/# (.*?)\n/g, '<h1>$1</h1>');
  return formattedText;
};

const GeneralTier = () => {
  const { chatId } = useParams();
  const [userInput, setUserInput] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const [currentChatId, setCurrentChatId] = useState(chatId || null);
  const [loadingChat, setLoadingChat] = useState(true);
  const [chatError, setChatError] = useState(null);
  const bottomRef = useRef(null);
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;
  const searchParams = new URLSearchParams(location.search);
  const isNewChat = searchParams.get('newChat') === 'true'
  const [showAttached, setShowAttached] = useState(false);

  useEffect(() => {
    if (isNewChat) {
      setCurrentChatId(null);
      setChatMessages([]);
      setLoadingChat(false);
      setChatError(null);
  
      // Optionally, remove the 'newChat' query parameter from the URL
      navigate('/general-tier', { replace: true, state: { userid } });
    }
  }, [isNewChat, navigate, userid]);

  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);

  useEffect(() => {
    setCurrentChatId(chatId || null);
  }, [chatId]);

  useEffect(() => {
    const initializeChat = async () => {
      if (!userid) {
        console.error('GeneralTier - User ID not found.');
        return;
      }
  
      if (currentChatId) {
        // Fetch existing chat
        const { data: chat, error: chatError } = await supabase
          .from('chats')
          .select('*')
          .eq('id', currentChatId)
          .single();
  
        if (chatError) {
          console.error('GeneralTier - Error fetching chat:', chatError.message);
          setChatError('Error fetching chat session.');
          setLoadingChat(false);
          return;
        }
  
        // Fetch messages associated with this chat
        const { data: messagesData, error: messagesError } = await supabase
          .from('messages')
          .select('*')
          .in('id', chat.messages)
          .order('created_at', { ascending: true });
  
        if (messagesError) {
          console.error('GeneralTier - Error fetching messages:', messagesError.message);
          setChatError('Error fetching messages.');
          setLoadingChat(false);
          return;
        }
  
        setChatMessages(messagesData.map(msg => ({
          sender: msg.user_created ? 'user' : 'gpt',
          content: msg.user_created ? msg.message : formatGptResponse(msg.message),
        })));
      }
  
      setLoadingChat(false);
    };
  
    initializeChat();
  }, [userid, currentChatId]);

  const fetchExampleEssays = async () => {
    const { data, error } = await supabase
      .from('questions')
      .select('*')
      .eq('code_word', 'General')
      .single();
  
    if (error) {
      console.error('Error fetching example essays:', error);
      return [];
    }
  
    return data.example_essays;
  };
  
  const handleGenerateOutput = async () => {
    if (!userInput.trim()) return;
    setShowAttached(false);
    setUserInput('');
    const textarea = document.querySelector('.user-input');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = '32px';
    }

    let chatIdtoUse = currentChatId;

    const userMessageId = uuidv4();
    const userMessageContent = userInput.trim();
    const userMessage = {
      id: userMessageId,
      created_at: new Date().toISOString(),
      user: userid,
      user_created: true,
      message: userMessageContent,
    };

    const { error: insertUserMsgError } = await supabase
    .from('messages')
    .insert(userMessage);

    if (insertUserMsgError) {
      console.error('GeneralTier - Error inserting user message:', insertUserMsgError.message);
      alert('Failed to send message. Please try again.');
      return;
    }

    // If no chat exists yet, create one
    if (!currentChatId) {
      const newChatId = uuidv4();
      const currentTime = new Date().toISOString();

      //Create chat title
      let chatTitle = userMessageContent;
      const maxTitleLength = 100;
      if (chatTitle.length > maxTitleLength) {
        chatTitle = chatTitle.substring(0, maxTitleLength) + '...';
      }


      const { error: createError } = await supabase
        .from('chats')
        .insert({
          id: newChatId,
          user: userid,
          most_recent_message: currentTime,
          school: 'general', // Set school to 'general'
          question: null,
          question_code: null,
          messages: [userMessageId],
          title: chatTitle,
        })
        .single();

      if (createError) {
        console.error('GeneralTier - Error creating new chat:', createError.message);
        alert('Error creating chat session. Please try again.');
        return;
      }

      chatIdtoUse = newChatId
      setCurrentChatId(newChatId);
      // console.log('GeneralTier - New chat created:', newChat);
    } else {
      const { data: currentChat, error: fetchChatError } = await supabase
      .from('chats')
      .select('messages')
      .eq('id', chatIdtoUse)
      .single();

      if (fetchChatError) {
        console.error('GeneralTier - Error fetching current chat:', fetchChatError.message);
        alert('Failed to update chat. Please try again.');
        return;
      }
      const updatedMessages = [...currentChat.messages, userMessageId];

      const { error: updateChatError } = await supabase
      .from('chats')
      .update({
        messages: updatedMessages,
        most_recent_message: new Date().toISOString(),
      })
      .eq('id', chatIdtoUse);

      if (updateChatError) {
        console.error('GeneralTier - Error updating chat with user message:', updateChatError.message);
        alert('Failed to update chat. Please try again.');
        return;
      }
  }
    setChatMessages(prevMessages => [...prevMessages, { sender: 'user', content: userInput.trim() }]);

    const previousMessages = chatMessages.map(msg => {
      const sender = msg.sender === 'user' ? 'User' : 'GPT';
      return `${sender}: ${msg.content}`;
    }).join('\n\n');

    const examples = await fetchExampleEssays();

    if (!examples || examples.length === 0) {
      console.error('No example essays found.');
      return;
    }

    const getFileContents = async (filePath, fileType) => {
      try {
        // Download file from Supabase Storage
        const { data, error } = await supabase.storage
          .from('attachments')
          .download(filePath);
        
        if (error) throw error;

        // Parse based on file type
        if (fileType === 'application/pdf') {
          const arrayBuffer = await data.arrayBuffer();
          const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
          let fullText = '';
          
          // Get text from each page
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            const pageText = textContent.items.map(item => item.str).join(' ');
            fullText += pageText + '\n';
          }
          
          return fullText;
        } else if (fileType.includes('word')) {
          const result = await mammoth.extractRawText({ arrayBuffer: await data.arrayBuffer() });
          return result.value;
        }
        
        return 'Unsupported file type';
      } catch (error) {
        console.error('Error reading file:', error);
        return `Error reading file: ${error.message}`;
      }
    };
    // Get contents of all attachments
    let attachmentsContent = 'No attachments';
    
    // Fetch current chat data if we have a chat ID
    if (chatIdtoUse) {
      const { data: chatData, error: chatError } = await supabase
        .from('chats')
        .select('*')
        .eq('id', chatIdtoUse)
        .single();

      if (chatError) {
        console.error('Error fetching chat data:', chatError);
      } else if (chatData?.attachments?.length > 0) {
        const attachmentsWithContent = await Promise.all(
          chatData.attachments.map(async (att) => {
            const content = await getFileContents(att.file_path, att.file_type);
            return `File: ${att.file_name}\nContent:\n${content}\n-------------------`;
          })
        );
        attachmentsContent = attachmentsWithContent.join('\n\n');
      }
    }
  
    const prompt = `
    You are an expert MBA essay consultant. Your goal is to assist clients in refining their essays by engaging in a natural, consultative conversation. This involves asking clarifying questions about any assumptions, providing constructive feedback, and guiding them toward the best possible output using the available data. UNDER NO CIRCUMSTANCES should you output any specific content from the example essays; these should only be used as guides to inform the results without extracting any specific or identifying information. The client will provide instructions, there is no pre-set question to answer.

    Guiding Principles:
    Be Specific: The more criteria you give, the more focused the output will be.
    Work in Steps: Break tasks into small chunks for better results.
    Iterate and Improve: Continuously refine inputs and improve outputs.
    Consultative Flow: Maintain a natural flow of conversation that feels consultative, focusing on collaboration and understanding the client's needs.
    Comprehensive Information Gathering: Do not generate a final version of the essay until you are confident you have all the information necessary to create a high-quality essay.
    Use Examples as Guides Only: Example essays are provided as references for high-quality writing and structure. Do not take or use any specific or identifying information from them.
    Main Instructions:
    Clarifying Assumptions:
    Objective: Identify and question any assumptions in the essay to ensure clarity and precision.
    Approach: Use probing questions to understand the context and the client’s intent behind certain statements or arguments.
    Example Questions:
    "Can you explain what you mean by this statement?"
    "What evidence supports this assumption?"
    "How does this point relate to your main argument?"
    Providing Constructive Feedback:
    Objective: Critique the structure, coherence, and logical flow of the essay.
    Approach: Highlight strengths and suggest improvements for weaknesses, focusing on clarity, conciseness, and relevance.
    Example Feedback:
    "This paragraph provides a strong argument, but it would benefit from more concrete examples."
    "Consider reorganizing these sections to enhance the logical flow of your essay."
    Guide Towards the Best Output:
    Objective: Offer specific suggestions for revision to elevate the essay’s quality.
    Approach: Provide examples or templates if needed and encourage iterative improvements by reviewing subsequent drafts.
    Example Guidance:
    "Here is a revised version of your thesis statement for better clarity."
    "Try using this structure for your introduction to make it more engaging."
    Iterative Improvement:
    Objective: Ensure the essay evolves to meet high-quality standards through continuous refinement.
    Approach: Encourage the client to make revisions based on feedback, offer to review revised drafts, and provide additional feedback. Repeat the process until the client is satisfied with the essay.
    Important Emphases:
    Do Not Generate Final Essay Prematurely: Ensure that you have gathered all necessary information and understand the client's objectives thoroughly before attempting to generate a final version of the essay.

    Use Example Essays Appropriately: While example essays serve as models of well-written, high-quality work, they should solely guide the structure and style of the client’s essay. Avoid incorporating any specific content or identifiable information from these examples into the client’s work.

    Example Interaction:
    Client: I have written an essay on climate change, but I feel it lacks impact. Can you help me improve it?

    GPT: Of course! Let’s start by understanding your goals better. Can you tell me more about the purpose of your essay and the audience you are targeting? Additionally, please share your draft so I can identify areas where we can enhance its impact.

    Client: The purpose is to raise awareness about the urgency of climate change, and the audience is the general public. [Shares draft]

    GPT: Thank you for sharing your draft. I noticed in your introduction you state, "Climate change is the most pressing issue of our time." What evidence can you provide to support this assertion? Additionally, your second paragraph discusses greenhouse gases but lacks specific examples. Including data on CO₂ emissions could strengthen your argument. Let’s work together to revise your thesis statement to make it clearer and more impactful before moving forward with the rest of the essay.

    Example Essays:
    ${examples.join('\n\n')}

    Previous chats from this thread in chronological order beginning with the first message every sent, refer to them for context on the user input:
    ${previousMessages}

    Attachments:
    ${attachmentsContent}
      
    User Input:
    ${userInput}
    `;
  
    setChatMessages([...chatMessages, { sender: 'user', content: userInput }]);

    try {
      const response = await fetch('https://chatsbc.adam-74c.workers.dev/openai', { // Update this URL based on your backend setup
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Backend error');
      }
  
      // Handle the streaming response
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';
      let generatedMessage = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        // Split by lines
        const lines = buffer.split('\n');
        buffer = lines.pop(); // Save the last partial line

        for (const line of lines) {
          const trimmedLine = line.trim();
          if (trimmedLine.startsWith('data: ')) {
            const dataStr = trimmedLine.replace(/^data: /, '');
            if (dataStr === '[DONE]') {
              break;
            }
            try {
              const data = JSON.parse(dataStr);
              const content = data.choices[0].delta.content;
              if (content) {
                generatedMessage += content;
                const formattedResponse = formatGptResponse(generatedMessage);

                setChatMessages(prevMessages => {
                  const updatedMessages = [...prevMessages];
                  const lastMessageIndex = updatedMessages.length - 1;
                  if (updatedMessages[lastMessageIndex]?.sender === 'gpt') {
                    updatedMessages[lastMessageIndex].content = formattedResponse;
                  } else {
                    updatedMessages.push({ sender: 'gpt', content: formattedResponse });
                  }

                  return updatedMessages;
                });
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }  
      // Insert AI message into messages table
      const messageId = uuidv4();
      const message = {
        id: messageId,
        created_at: new Date().toISOString(),
        user: userid,
        user_created: false,
        message: generatedMessage,
      };
      const { error: insertMsgError } = await supabase
        .from('messages')
        .insert(message);
  
      if (insertMsgError) {
        console.error('GeneralTier - Error inserting AI message:', insertMsgError.message);
        return;
      }
  
      // Update chats table
      const { data: currentChat, error: fetchChatError } = await supabase
        .from('chats')
        .select('messages')
        .eq('id', chatIdtoUse)
        .single();
  
      if (fetchChatError) {
        console.error('GeneralTier - Error fetching current chat:', fetchChatError.message);
        return;
      }
  
      const updatedMessages = [...currentChat.messages, messageId];
      const { error: updateChatError } = await supabase
        .from('chats')
        .update({
          messages: updatedMessages,
          most_recent_message: new Date().toISOString(),
        })
        .eq('id', chatIdtoUse);
  
      if (updateChatError) {
        console.error('GeneralTier - Error updating chat with AI message:', updateChatError.message);
        return;
      }
    } catch (error) {
      console.error('GeneralTier - Error generating AI response:', error);
    }
  };

  const handleAttachFile = async () => {
    // First ensure we have a valid chatId
    let chatIdToUse = currentChatId;
    if (!chatIdToUse) {
      // Create a new chat if one doesn't exist
      const newChatId = uuidv4();
      const currentTime = new Date().toISOString();
      
      const { error: createError } = await supabase
        .from('chats')
        .insert({
          id: newChatId,
          user: userid,
          most_recent_message: currentTime,
          school: 'general',
          messages: [],
          attachments: [],
          title: 'File Attachment'
        })
        .single();

      if (createError) {
        console.error('Error creating new chat:', createError);
        alert('Error creating chat session. Please try again.');
        return;
      }
      
      chatIdToUse = newChatId;
      setCurrentChatId(newChatId);
    }

    // Create file input element
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf,.doc,.docx';
    
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;
      
      // Validate file type
      const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!validTypes.includes(file.type)) {
        alert('Please upload only PDF or Word documents');
        return;
      }
      
      try {
        // Show loading state
        // alert('Uploading file...');
        
        // Create a unique filename to prevent collisions
        const uniqueFileName = `${userid}/${chatIdToUse}/${Date.now()}-${file.name}`;
        
        // Upload file to Supabase Storage
        const { error: uploadError } = await supabase.storage
          .from('attachments')
          .upload(uniqueFileName, file);
          
        if (uploadError) throw uploadError;

        // Get the public URL for the uploaded file
        const { data: { publicUrl } } = supabase.storage
          .from('attachments')
          .getPublicUrl(uniqueFileName);
          
        // Fetch current attachments array
        const { data: existingChat, error: fetchError } = await supabase
          .from('chats')
          .select('attachments')
          .eq('id', chatIdToUse)
          .single();
          
        if (fetchError) throw fetchError;
          
        // Prepare new attachment metadata
        const newAttachment = {
          file_name: file.name,
          file_path: uniqueFileName,
          file_type: file.type,
          file_size: file.size,
          uploaded_at: new Date().toISOString(),
          public_url: publicUrl
        };
          
        // Create new attachments array with existing attachments (if any)
        const updatedAttachments = [
          ...(existingChat?.attachments || []),
          newAttachment
        ];
          
        // Update the chat record with new attachments array
        const { error: updateError } = await supabase
          .from('chats')
          .update({ attachments: updatedAttachments })
          .eq('id', chatIdToUse);
          
        if (updateError) throw updateError;
          
        setShowAttached(true);

      } catch (error) {
        console.error('Error handling file:', error);
        alert('Error uploading file. Please try again.');
      }
    };
    
    // Trigger file selection
    input.click();
  };

  return (
    <div className="chat-page">
      <GeneralBanner userid={userid} />
      <div className="chat-window">
        <div className="logo-and-question">
          <img src={Logo} alt="Logo" className="logo" />
        </div>
        {loadingChat && <div>Loading chat...</div>}
        {chatError && <div>Error: {chatError}</div>}
        {chatMessages.map((message, index) => (
          <div
            key={index}
            className={`chat-message ${message.sender}`}
            dangerouslySetInnerHTML={{ __html: message.content }}
          ></div>
        ))}
        <div ref={bottomRef}></div> 
      </div>
      <div className="chat-input-container">
        <button className="paperclip" onClick={handleAttachFile}>
          <img src={showAttached ? Attached : Paperclip} alt="Attach" />
        </button>
        <textarea
          className="user-input"
          placeholder="Enter your input here..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onInput={(e) => {
            e.target.style.height = "auto";
            e.target.style.height = (e.target.scrollHeight - 10) + "px";  
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleGenerateOutput();
            }
          }}
        />
        <button className="generate-button" onClick={handleGenerateOutput}>
          <img src={SubmitIcon} alt="Submit" />
        </button>
      </div>
    </div>
  );
};

export default GeneralTier;