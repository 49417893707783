import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Virginia.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "What would you want your classmates to know about you that is not on your resume?"
  },
  {
    text: "Please describe a tangible example that illuminates your experience promoting an inclusive environment and what you would bring to creating a welcoming, global community at Darden."
  },
  {
    text: "At this time how would you describe your short-term, post-MBA goal in terms of industry, function, geography, company size and/or mission and how does it align with the long-term vision you have for your career?"
  }
]

const Virginia = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/virginia/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Virginia Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Virginia;