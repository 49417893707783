import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './ButtonGrid.css';
import Berkeley from './Assets/Berkeley.jpg';
import Chicago from './Assets/Chicago.jpg';
import Columbia from './Assets/Columbia.jpg';
import Cornell from './Assets/Cornell.jpg';
import Dartmouth from './Assets/Dartmouth.jpg';
import Duke from './Assets/Duke.jpg';
import Harvard from './Assets/Harvard.jpg';
import Michigan from './Assets/Michigan.jpg';
import MIT from './Assets/MIT.jpg';
import Northwestern from './Assets/Northwestern.jpg';
import NYU from './Assets/NYU.jpg';
import Penn from './Assets/Penn.jpg';
import Stanford from './Assets/Stanford.jpg';
import UCLA from './Assets/UCLA.jpg';
import Virginia from './Assets/Virginia.jpg';
import Yale from './Assets/Yale.jpg';
import HEC from './Assets/HEC.jpg';
import INSEAD from './Assets/INSEAD.jpg';
import LBS from './Assets/LBS.jpg';
import UNC from './Assets/UNC.jpg';
import USC from './Assets/USC.jpg';
import Banner from './Banner.js';
import supabase from './supabaseClient.js';

const images = [
  { name: 'Penn', src: Penn, link: '/penn' },
  { name: 'Harvard', src: Harvard, link: '/harvard' },
  { name: 'Stanford', src: Stanford, link: '/stanford' },
  { name: 'Northwestern', src: Northwestern, link: '/northwestern' },
  { name: 'Columbia', src: Columbia, link: '/columbia' },
  { name: 'NYU', src: NYU, link: '/nyu' },
  { name: 'Duke', src: Duke, link: '/duke' },
  { name: 'Chicago', src: Chicago, link: '/chicago' },
  { name: 'MIT', src: MIT, link: '/mit' },
  { name: 'Dartmouth', src: Dartmouth, link: '/dartmouth' },
  { name: 'Michigan', src: Michigan, link: '/michigan' },
  { name: 'UCLA', src: UCLA, link: '/ucla' },
  { name: 'Berkeley', src: Berkeley, link: '/berkeley' },
  { name: 'Yale', src: Yale, link: '/yale' },
  { name: 'Cornell', src: Cornell, link: '/cornell' },
  { name: 'Virginia', src: Virginia, link: '/virginia' },
  { name: 'INSEAD', src: INSEAD, link: '/insead' },
  { name: 'LBS', src: LBS, link: '/lbs' },
  { name: 'USC', src: USC, link: '/usc' },
  { name: 'UNC', src: UNC, link: '/unc' },
  { name: 'HEC', src: HEC, link: '/hec' },
];

const ButtonGrid = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const [userSchools, setUserSchools] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUserSchools = async () => {
      if (!userid) {
        setError('User ID not found.');
        setLoading(false);
        return;
      }

      // Fetch the user's profile to get the schools array
      const { data: profileData, error: profileError } = await supabase
        .from('profiles')
        .select('schools')
        .eq('id', userid)
        .single();

      if (profileError) {
        console.error('Error fetching profile:', profileError.message);
        setError('Error fetching profile: ' + profileError.message);
      } else {
        // console.log('User schools:', profileData.schools);
        setUserSchools(profileData.schools || []);
      }

      setLoading(false);
    };
    fetchUserSchools();
  }, [userid]);

  const handleButtonClick = (link) => {
    if (link) {
      navigate(link, { state: { userid } });
    }
  };

  if (loading) {
    return (
      <div className="button-grid-container">
        <Banner />
        <div>Loading...</div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="button-grid-container">
        <Banner />
        <div>Error: {error}</div>
      </div>
    );
  }

  // Filter images to only include user's schools
  const filteredImages = images.filter((image) =>
    userSchools.includes(image.name)
  );

  return (
    <div className="button-grid-container">
    <Banner userid={userid} />
    <div className="button-grid">
      {filteredImages.map((image, index) => (
        <button 
          key={index} 
          className="grid-button" 
          onClick={() => handleButtonClick(image.link)}
        >
          <img src={image.src} alt={image.name} />
        </button>
      ))}
    </div>
    </div>
  );
};

export default ButtonGrid;
