import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Dartmouth.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "Why are you pursuing an MBA and why now? How will the distinct Tuck MBA contribute to achieving your goals and aspirations?"
  },
  {
    text: "Tell us who you are. How have your values and experiences shaped your identity and character? How will your background contribute to the diverse Tuck culture and community?"
  },
  {
    text: "Describe a time you meaningfully contributed to someone else’s sense of inclusion in your professional or personal community."
  }
]

const Dartmouth = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/dartmouth/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Dartmouth Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Dartmouth;