import React, { useState } from 'react';
import Password from './Password.js';
import { UserProvider } from './userContext.js';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import ButtonGrid from './ButtonGrid.js';
import Login from './Login.js';
import SignUp from './SignUp.js';
import Tiers from './Tiers.js';
import SelectSchools from './SelectSchools.js';
import GeneralTier from './GeneralTier.js';

import Columbia from './Schools/Columbia.js';
import Cornell from './Schools/Cornell.js';
import Dartmouth from './Schools/Dartmouth.js';
import Duke from './Schools/Duke.js';
import Harvard from './Schools/Harvard.js';
import MIT from './Schools/MIT.js';
import NYU from './Schools/NYU.js'
import Stanford from './Schools/Stanford.js';
import Berkeley from './Schools/Berkeley.js'
import UCLA from './Schools/UCLA.js';
import Chicago from './Schools/Chicago.js';
import Michigan from './Schools/Michigan.js';
import Penn from './Schools/Penn.js';
import Virginia from './Schools/Virginia.js';
import Yale from './Schools/Yale.js';
import Northwestern from './Schools/Northwestern.js';
import HEC from './Schools/HEC.js';
import INSEAD from './Schools/INSEAD.js';
import LBS from './Schools/LBS.js';
import UNC from './Schools/UNC.js';
import USC from './Schools/USC.js';

import ChatPage from './ChatPage.js';
import Checkout from './Checkout.js';
import Settings from './Settings.js';

import './App.css';

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  if (!isAuthenticated) {
    return <Password onCorrectPassword={() => setIsAuthenticated(true)} />;
  }

  return (
    <UserProvider>
      <Router>
        <div className="App">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route path="/" element={<Navigate to="/login" replace />} />
            <Route path="/button-grid" element={<ButtonGrid />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/tiers" element={<Tiers />} />
            <Route path="/select-schools" element={<SelectSchools />} />
            <Route path="/columbia" element={<Columbia />} />
            <Route path="/cornell" element={<Cornell />} />
            <Route path="/dartmouth" element={<Dartmouth />} />
            <Route path="/duke" element={<Duke />} />
            <Route path="/harvard" element={<Harvard />} />
            <Route path="/mit" element={<MIT />} />
            <Route path="/nyu" element={<NYU />} />
            <Route path="/stanford" element={<Stanford />} />
            <Route path="/berkeley" element={<Berkeley />} />
            <Route path="/ucla" element={<UCLA />} />
            <Route path="/chicago" element={<Chicago />} />
            <Route path="/michigan" element={<Michigan />} />
            <Route path="/penn" element={<Penn />} />
            <Route path="/virginia" element={<Virginia />} />
            <Route path="/yale" element={<Yale />} />
            <Route path="/northwestern" element={<Northwestern />} />
            <Route path="/hec" element={<HEC />} />
            <Route path="/insead" element={<INSEAD />} />
            <Route path="/lbs" element={<LBS />} />
            <Route path="/unc" element={<UNC />} />
            <Route path="/usc" element={<USC />} />
            <Route path="/chat/:school/:question" element={<ChatPage />} />
            <Route path="/checkout" element={<Checkout />} />
            <Route path="/general-tier/:chatId?" element={<GeneralTier />} />
            <Route path="/settings" element={<Settings />} />
          </Routes>
        </div>
      </Router>
    </UserProvider>
  );
}

export default App;
