import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/INSEAD.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "Briefly summarize your current (or most recent) job, including the nature of work, major responsibilities, and where relevant, employees under your supervision, size of budget, clients/products and results achieved."
  },
  {
    text: "What would be your next step in terms of position if you were to remain in the same company instead of going to business school?"
  },
  {
    text: "Please give a full description of your career since graduating from university. Describe your career path with the rationale behind your choices."
  },
  {
    text: "Discuss your short and long term career aspirations with an MBA from INSEAD."
  },
  {
    text: "Give a candid description of yourself (who are you as a person), stressing the personal characteristics you feel to be your strengths and weaknesses and the main factors which have influenced your personal development, giving examples when necessary."
  },
  {
    text: "Describe the achievement of which you are most proud and explain why. In addition, describe a situation where you failed. How did these experiences impact your relationships with others? Comment on what you learned."
  },
  {
    text: "Is there anything else that was not covered in your application that you would like to share with the Admissions Committee?"
  }
]

const INSEAD = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/insead/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="INSEAD Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default INSEAD;