import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/UNC.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "What are your immediate career goals and how will you benefit from earning an MBA at Kenan-Flagler Business School? As the business world continues to evolve, circumstances can change that can guide you in a different direction. Should your goals that you provided above not transpire, what other opportunities would you explore?"
  },
  {
    text: "Reflect on a project or initiative you were involved in that had a significant positive impact on a community, organization, or individual. How did this experience shape your sense of responsibility as a future leader dedicated to making a difference in the world?"
  },
  {
    text: "We encourage you to tell us more about who you are so we can get to know you as a person and potential member of the Carolina community. Use this essay to convey information that you may not have otherwise been able to in the application."
  }
]

const UNC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/unc/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="UNC Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default UNC;