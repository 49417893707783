import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Duke.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "What are your post-MBA career goals? Share with us your first-choice career plan and your alternate plan."
  },
  {
    text: "The 'Team Fuqua' spirit and community is one of the things that sets the MBA experience apart, and it is a concept that extends beyond the student body to include faculty, staff, and administration. Please share with us “25 Random Things” about you. The Admissions Committee wants to get to know YOU - beyond the professional and academic achievements listed in your resume and transcript. Share with us important life experiences, your hobbies, achievements, fun facts, or anything that helps us understand what makes you who you are."
  },
  {
    text: "Fuqua prides itself on cultivating a culture of engagement. Our students enjoy a wide range of student-led organizations that provide opportunities for leadership development and personal fulfillment, as well as an outlet for contributing to society. Our student-led government, clubs, centers, and events are an integral part of the student culture and to the development of leaders. Based on your understanding of the Fuqua culture, what are 3 ways you expect to contribute at Fuqua?"
  },
  {
    text: "If you feel there are circumstances of which the admissions committee should be aware, please explain them here (e.g. unexplained gaps in work, choice of recommenders, inconsistent or questionable academic performance). Note that you should NOT upload additional essays nor additional recommendations in this area. The Optional Information section is intended to provide the admissions committee with insight into your circumstances only."
  }
]

const Duke = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/duke/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Duke Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Duke;