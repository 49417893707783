import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/UCLA.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "Why are you pursuing an MBA?"
  },
  {
    text: "Describe your short-term and long-term post-MBA career goals."
  },
  {
    text: "At Anderson, our mission is to prepare transformative leaders by developing students to think innovatively, problem-solve, and adapt to others – all with a driven and purposeful mindset. How do you plan to achieve your goals and develop into a transformative leader during your time at UCLA Anderson?"
  },
  {
    text: "No preference is given in the evaluation process to those who choose to respond to this optional essay, so please use your best judgment: Are there any extenuating circumstances in your profile about which the Admissions committee should be aware?"
  }
]

const UCLA = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/ucla/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="UCLA Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default UCLA;