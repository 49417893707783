import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import UnlinkedBanner from './unlinkedBanner.js';
import './Checkout.css';
// import { useUser } from './userContext.js';
import supabase from './supabaseClient.js'

function Checkout() {
  const location = useLocation();
  const navigate = useNavigate();
  // const { user } = useUser();
  const tier = location.state?.tier || 'Default';
  const schools = location.state?.schools || [];
  const price = tier === 'Individual' ? schools.length * 100 : location.state?.price || 0;
  const userid = location.state?.userid;

  // Add state for form fields
  const [formData, setFormData] = useState({
    cardNumber: '',
    expiryDate: '',
    cvv: '',
    cardholderName: ''
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({
      ...prev,
      [name]: value
    }));
  };

  const handleCheckout = async () => {
    if (!userid) {
      console.error('User ID not found.');
      alert('User ID not found. Please log in again.');
      return;
    }

    // Map the tier to the enum values in your database
    const tierValue = tier === 'General' ? 'General' : 'Individual';

    // Update the profile in Supabase
    const { error: updateError } = await supabase
      .from('profiles')
      .update({
        tier: tierValue,
        schools: schools.length > 0 ? schools : null, // Only update if schools are selected
      })
      .eq('id', userid);

    if (updateError) {
      console.error('Error updating profile:', updateError.message);
      alert('Error updating profile: ' + updateError.message);
      return;
    } else {
      // console.log('Profile updated:', data);
    }

    // Navigate to next page
    if (tier === 'General') {
      navigate('/general-tier', { state: { userid, tier, price } });
    } else if (tier === 'Individual') {
      navigate('/button-grid', { state: { userid, tier, price, schools } });
    } else {
      // console.log('Unsupported tier');
    }
  };

  return (
    <div className="main-container">
      <UnlinkedBanner />
      <div className="checkout-container">
        {/* Left side - Receipt */}
        <div className="receipt-section">
          <h2>Order Summary</h2>
          <div className="receipt-details">
            <div className="receipt-item">
              <span>Tier:</span>
              <span className="tier-badge">{tier}</span>
            </div>
            
            {tier === 'Individual' && (
              <div className="schools-list">
                <h3>Selected Schools:</h3>
                <ul>
                  {schools.map((school, index) => (
                    <li key={index}>{school}</li>
                  ))}
                </ul>
              </div>
            )}
            
            <div className="receipt-total">
              <span>Total Amount:</span>
              <span className="price">${price}</span>
            </div>
          </div>
        </div>

        {/* Right side - Payment Method */}
        <div className="payment-section">
          <h2>Payment Method</h2>
          <div className="payment-form">
            <div className="form-group">
              <label>Card Number</label>
              <input 
                type="text" 
                name="cardNumber"
                placeholder="1234 5678 9012 3456" 
                value={formData.cardNumber}
                onChange={handleInputChange}
                maxLength="19"
              />
            </div>
            
            <div className="form-row">
              <div className="form-group">
                <label>Expiry Date</label>
                <input 
                  type="text" 
                  name="expiryDate"
                  placeholder="MM/YY" 
                  value={formData.expiryDate}
                  onChange={handleInputChange}
                  maxLength="5"
                />
              </div>
              <div className="form-group">
                <label>CVV</label>
                <input 
                  type="text" 
                  name="cvv"
                  placeholder="123" 
                  value={formData.cvv}
                  onChange={handleInputChange}
                  maxLength="3"
                />
              </div>
            </div>

            <div className="form-group">
              <label>Cardholder Name</label>
              <input 
                type="text" 
                name="cardholderName"
                placeholder="John Doe"
                value={formData.cardholderName}
                onChange={handleInputChange}
              />
            </div>

            <button className="checkout-button" onClick={handleCheckout}>
              Complete Purchase
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Checkout;
