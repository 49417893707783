// SignUp.js
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SignUp.css';
import Logo from './Assets/Logo.jpg';
import UnlinkedBanner from './unlinkedBanner.js';
import supabase from './supabaseClient.js'; // Correct import without curly braces

function SignUp() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Attempt to sign up the user with Supabase authentication
    const { data: signUpData, error: signUpError } = await supabase.auth.signUp({
      email: email,
      password: password,
      options: {
        emailRedirectTo: '', // Leave empty or set to null
      },
    });

    if (signUpError) {
      console.error('Error signing up:', signUpError.message);
      alert('Sign up failed: ' + signUpError.message);
    } else {
      const user = signUpData.user;

      if (user) {
        // Create a profile for the new user
        const { error: newProfileError } = await supabase
          .from('profiles')
          .insert({
            id: user.id,
            tier: 'General', // Default tier
            chats: [], // Initialize with an empty array
            schools: [], // Initialize with an empty array
          });

        if (newProfileError) {
          console.error('Error creating profile:', newProfileError.message);
          alert('Error creating profile: ' + newProfileError.message);
        } else {
          // console.log('Profile created:', newProfile);
          // Navigate to the next page after successful sign-up
          navigate('/tiers', { state: { userid: user.id } });
        }
      } else {
        console.error('User data not available after sign-up.');
        alert('Sign up failed: User data not available.');
      }
    }
  };

  return (
    <div className="signup-container">
      <UnlinkedBanner />
      <div className="signup-form">
        <img src={Logo} alt="Logo" className="form-logo" />
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Sign Up</button>
        </form>
      </div>
      <div className="login-link">
        <Link to="/login">Already have an account? Log in</Link>
      </div>
    </div>
  );
}

export default SignUp;