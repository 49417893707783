import React, { useState, useEffect } from 'react';
import './Password.css';

const Password = ({ onCorrectPassword }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const handleMouseMove = (e) => {
      const container = document.querySelector('.password-container');
      if (container) {
        const { clientX, clientY } = e;
        container.style.setProperty('--x', `${clientX}px`);
        container.style.setProperty('--y', `${clientY}px`);
        
        const afterElement = container.querySelector('::after');
        if (afterElement) {
          afterElement.style.left = `${clientX}px`;
          afterElement.style.top = `${clientY}px`;
        }
      }
    };

    document.addEventListener('mousemove', handleMouseMove);
    return () => document.removeEventListener('mousemove', handleMouseMove);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    if (password === 'starlight') {
      onCorrectPassword();
    } else {
      setError(true);
      setTimeout(() => setError(false), 2000);
    }
  };

  return (
    <div className="password-container">
      <div className="password-box">
        <h1>Welcome</h1>
        <form onSubmit={handleSubmit}>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="Enter password"
            className={error ? 'error' : ''}
          />
          <button type="submit">Enter</button>
        </form>
        {error && <p className="error-message">Incorrect password</p>}
      </div>
    </div>
  );
};

export default Password;
