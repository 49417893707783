import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Yale.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "Briefly describe your career interests and how you arrived at them. What have you already done to pursue these interests? What do you need to do going forward?"
  },
  {
    text: "Describe the biggest commitment you have ever made. Why is this commitment meaningful to you and what actions have you taken to support it?"
  },
  {
    text: "Describe the most significant challenge you have faced. How have you confronted this challenge and how has it shaped you as a person?"
  },
  {
    text: "If any aspect of your application requires additional explanation, you can address it in the Optional Information section below.  Please note, you should use the specific prompts provided in the Work Experience section to address gaps in work experience or choice of recommender. The Optional Information section is truly optional – if no aspect of your application requires further explanation, you should leave this section blank."
  }
]

const Yale = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/yale/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Yale Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Yale;