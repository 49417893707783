import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Penn.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "How do you plan to use the Wharton MBA program to help you achieve your future professional goals? You might consider your past experience, short and long-term goals, and resources available at Wharton."
  },
  {
    text: "Taking into consideration your background – personal, professional, and/or academic – how do you plan to make specific, meaningful contributions to the Wharton community?"
  },
  {
    text: "Please use this space to share with the Admissions Committee how you have reflected and grown since your previous application and discuss any relevant updates to your candidacy (e.g., changes in your professional life, additional coursework, and extracurricular/volunteer engagements)."
  },
  {
    text: "Please use this space to share any additional information about yourself that cannot be found elsewhere in your application and that you would like to share with the Admissions Committee. This space can also be used to address any extenuating circumstances (e.g., unexplained gaps in work experience, choice of recommenders, inconsistent or questionable academic performance, areas of weakness, etc.) that you would like the Admissions Committee to consider."
  }
]

const Penn = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/penn/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Penn Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Penn;