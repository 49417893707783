import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Chicago.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "How will a Booth MBA help you achieve your immediate and long-term post-MBA career goals?"
  },
  {
    text: "An MBA is as much about personal growth as it is about professional development. In addition to sharing your experience and goals in terms of career, we’d like to learn more about you outside of the office. Use this opportunity to tell us something about who you are…"
  },
  {
    text: "Is there any unclear information in your application that needs further explanation?"
  },
  {
    text: "Upon reflection, how has your perspective regarding your future, Chicago Booth, and/or getting an MBA changed since the time of your last application?"
  }
]

const Chicago = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/chicago/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Chicago Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Chicago;