import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Northwestern.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "Intentionality is a key aspect of what makes our graduates successful Kellogg leaders. Help us understand your journey by articulating your motivations for pursuing an MBA, the specific goals you aim to achieve, and why you believe now is the right moment. Moreover, share why you feel Kellogg is best suited to serve as a catalyst for your career aspirations and what you will contribute to our community of lifelong learners during your time here."
  },
  {
    text: "Kellogg leaders are primed to tackle challenges everywhere, from the boardroom to their neighborhoods. Describe a specific professional experience where you had to make a difficult decision. Reflecting on this experience, identify the values that guided your decision-making process and how it impacted your leadership style."
  },
  {
    text: "How have you grown or changed personally and professionally since you previously applied and what steps have you taken to become the strongest candidate you can be?"
  },
  {
    text: "We know that life is full of extenuating circumstances. Whether you want to explain gaps in work experience, your choice of recommenders, inconsistent or questionable academic performance or something else, you can use this section to briefly tell us anything we need to know about your application."
  },
  {
    text: "Video questions"
  }
]

const Northwestern = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/northwestern/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Northwestern Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Northwestern;