import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/LBS.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "What are your post-MBA goals and how will your prior experience and the London Business School programme contribute towards these?"
  },
  {
    text: "What makes you unique?"
  },
  {
    text: "Is there any other information you believe the Admissions Committee should know about you and your application to London Business School?"
  }
]

const LBS = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/lbs/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="LBS Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default LBS;