import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './Login.css';
import Logo from './Assets/Logo.jpg';
import UnlinkedBanner from './unlinkedBanner.js';
import supabase from './supabaseClient.js';

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    // Attempt to sign in the user with Supabase authentication
    const { data: signInData, error: signInError } = await supabase.auth.signInWithPassword({
      email: email,
      password: password,
    });

    if (signInError) {
      console.error('Error signing in:', signInError.message);
      alert('Login failed: ' + signInError.message);
    } else {
      const user = signInData.user;

      if (user) {
        // Fetch the user's profile to get the tier
        const { data: profileData, error: profileError } = await supabase
          .from('profiles')
          .select('tier')
          .eq('id', user.id)
          .single(); // Use .single() to get one record

        if (profileError) {
          console.error('Error fetching profile:', profileError.message);
          alert('Error fetching profile: ' + profileError.message);
        } else {
          const tier = profileData.tier;
          // console.log('User tier:', tier);

          // Navigate based on the tier
          if (tier === 'General') {
            navigate('/general-tier', { state: { userid: user.id } });
          } else if (tier === 'Individual') {
            navigate('/button-grid', { state: { userid: user.id } });
          } else {
            console.error('Unknown tier:', tier);
            alert('Unknown tier: ' + tier);
          }
        }
      } else {
        console.error('User data not available after sign-in.');
        alert('Login failed: User data not available.');
      }
    }
  };

  return (
    <div className="login-container">
      <UnlinkedBanner />
      <div className="login-form">
        <img src={Logo} alt="Logo" className="form-logo" />
        <form onSubmit={handleSubmit}>
          <input
            type="email"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <input
            type="password"
            placeholder="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
          <button type="submit">Login</button>
        </form>
      </div>
      <div className="create-account">
        <Link to="/signup">Create Account</Link>
      </div>
    </div>
  );
}

export default Login;