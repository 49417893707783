import React, { useState, useEffect, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import supabase from './supabaseClient.js';
import Banner from './Banner.js';
import Columbia from './Assets/Columbia.jpg';
import Berkeley from './Assets/Berkeley.jpg';
import Chicago from './Assets/Chicago.jpg';
import Cornell from './Assets/Cornell.jpg';
import Dartmouth from './Assets/Dartmouth.jpg';
import Duke from './Assets/Duke.jpg';
import Harvard from './Assets/Harvard.jpg';
import Michigan from './Assets/Michigan.jpg';
import MIT from './Assets/MIT.jpg';
import Northwestern from './Assets/Northwestern.jpg';
import NYU from './Assets/NYU.jpg';
import Penn from './Assets/Penn.jpg';
import Stanford from './Assets/Stanford.jpg';
import UCLA from './Assets/UCLA.jpg';
import Virginia from './Assets/Virginia.jpg';
import Yale from './Assets/Yale.jpg';
import HEC from './Assets/HEC.jpg';
import INSEAD from './Assets/INSEAD.jpg';
import LBS from './Assets/LBS.jpg';
import UNC from './Assets/UNC.jpg';
import USC from './Assets/USC.jpg';

import SubmitIcon from './Assets/SubmitIcon.png';
import Paperclip from './Assets/Paperclip.png';
import Attached from './Assets/Attached.png';

import * as pdfjsLib from 'pdfjs-dist';
import mammoth from 'mammoth';

import { v4 as uuidv4 } from 'uuid';

import './ChatPage.css';

// Set worker properly
pdfjsLib.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.mjs',
  import.meta.url
).toString();

const formatGptResponse = (text) => {
  let formattedText = text.replace(/\*\*(.*?)\*\*/g, '<strong>$1</strong>');
  formattedText = formattedText.replace(/### (.*?)\n/g, '<h3>$1</h3>');
  formattedText = formattedText.replace(/## (.*?)\n/g, '<h2>$1</h2>');
  formattedText = formattedText.replace(/# (.*?)\n/g, '<h1>$1</h1>');
  return formattedText;
};

const questionCodes = {
    columbia: {
      "Through your resume and recommendation, we have a clear sense of your professional path to date. What are your career goals over the next three to five years and what is your long-term dream job?": "Goals",
      "The Phillips Pathway for Inclusive Leadership (PPIL) is a co-curricular program designed to provide students with the skills and strategies needed to develop as inclusive leaders.  Through various resources and programming, students explore and reflect on the following five inclusive leadership skills:  Mitigating Bias and Prejudice; Managing Intercultural Dialogue; Addressing Systemic Inequity; Understanding Identity and Perspective Taking; and Creating an Inclusive Environment. Describe a time or situation when you had the need to utilize one of these five skills, and tell us the actions you took and the outcome.": "PPIL",
      "We believe Columbia Business School is a special place with a collaborative learning environment in which students feel a sense of belonging, agency, and partnership--academically, culturally, and professionally. How would you co-create your optimal MBA experience at CBS? Please be specific.": "Why",
      "If you wish to provide further information or additional context around your application to the Admissions Committee, please upload a brief explanation of any areas of concern in your academic record or personal history. This does not need to be a formal essay. You may submit bullet points.": "Optional"
    },
    cornell: {
      "Goals Statement: Use this short-answer section to succinctly share your short- and long-term goals. If you’re invited to interview, you will have the opportunity to elaborate on your statement further, and you should be prepared to connect your prior experience with your future aspirations.": "Goals",
      "At Cornell, our students and alumni share a desire to positively impact the organizations and communities they serve. How do you intend to make a meaningful impact on the Johnson community?":"Impact",
      "What is something unique about you that others will remember you by?":"Unique",
      "You may use this essay to call attention to items needing clarification and to add additional details to any aspects of your application that do not accurately reflect your potential for success at Johnson.":"Optional",
    },
    dartmouth: {
      "Why are you pursuing an MBA and why now? How will the distinct Tuck MBA contribute to achieving your goals and aspirations?":"Why",
      "Tell us who you are. How have your values and experiences shaped your identity and character? How will your background contribute to the diverse Tuck culture and community?":"Who",
      "Describe a time you meaningfully contributed to someone else’s sense of inclusion in your professional or personal community.":"Inclusion"
    },
    duke: {
      "What are your post-MBA career goals? Share with us your first-choice career plan and your alternate plan.":"Goals",
      "The 'Team Fuqua' spirit and community is one of the things that sets the MBA experience apart, and it is a concept that extends beyond the student body to include faculty, staff, and administration. Please share with us “25 Random Things” about you. The Admissions Committee wants to get to know YOU - beyond the professional and academic achievements listed in your resume and transcript. Share with us important life experiences, your hobbies, achievements, fun facts, or anything that helps us understand what makes you who you are.":"Things",
      "Fuqua prides itself on cultivating a culture of engagement. Our students enjoy a wide range of student-led organizations that provide opportunities for leadership development and personal fulfillment, as well as an outlet for contributing to society. Our student-led government, clubs, centers, and events are an integral part of the student culture and to the development of leaders. Based on your understanding of the Fuqua culture, what are 3 ways you expect to contribute at Fuqua?":"Contribute",
      "If you feel there are circumstances of which the admissions committee should be aware, please explain them here (e.g. unexplained gaps in work, choice of recommenders, inconsistent or questionable academic performance). Note that you should NOT upload additional essays nor additional recommendations in this area. The Optional Information section is intended to provide the admissions committee with insight into your circumstances only.":"Optional"
    },
    harvard: {
      "Please reflect on how your experiences have influenced your career choices and aspirations and the impact you strive to make on the businesses, organizations, and communities you plan to serve.":"Career",
      "What experiences have shaped who you are, how you invest in others, and what kind of leader you want to become?":"Leader",
      "Curiosity can be seen in many ways. Please share an example of how you have demonstrated curiosity and how that has influenced your growth.":"Curiosity"
    },
    hec: {
      "Why are you applying to the HEC MBA Program now? What is the professional objective that will guide your career choice after your MBA, and how will the HEC MBA contribute to the achievement of this objective?":"Why",
      "What do you consider your most significant life achievement?":"Achievement",
      "Leadership and ethics are inevitably intertwined in the business world. Describe a situation in which you have dealt with these issues and how they have influenced you.":"Leadership",
      "Imagine a life entirely different from the one you now lead, what would it be?":"Life",
      "If there is anything else you would like to share with us that has not already been shared elsewhere in your file, please feel free to use this space.":"Optional"
    },
    insead: {
      "Briefly summarize your current (or most recent) job, including the nature of work, major responsibilities, and where relevant, employees under your supervision, size of budget, clients/products and results achieved.":"Job",
      "What would be your next step in terms of position if you were to remain in the same company instead of going to business school?":"Next",
      "Please give a full description of your career since graduating from university. Describe your career path with the rationale behind your choices.":"Career",
      "Discuss your short and long term career aspirations with an MBA from INSEAD.":"Aspirations",
      "Give a candid description of yourself (who are you as a person), stressing the personal characteristics you feel to be your strengths and weaknesses and the main factors which have influenced your personal development, giving examples when necessary.":"Description",
      "Describe the achievement of which you are most proud and explain why. In addition, describe a situation where you failed. How did these experiences impact your relationships with others? Comment on what you learned.":"Achievement",
      "Is there anything else that was not covered in your application that you would like to share with the Admissions Committee?":"Optional"
    },
    lbs: {
      "What are your post-MBA goals and how will your prior experience and the London Business School programme contribute towards these?":"Goals",
      "What makes you unique?":"Unique",
      "Is there any other information you believe the Admissions Committee should know about you and your application to London Business School?":"Optional"
    },
    mit: {
      "MIT Sloan seeks students whose personal characteristics demonstrate that they will make the most of the incredible opportunities at MIT, both academic and non-academic. We are on a quest to find those whose presence will enhance the experience of other students. We seek thoughtful leaders with exceptional intellectual abilities and the drive and determination to put their stamp on the world. We welcome people who are independent, authentic, and fearlessly creative — true doers. We want people who can redefine solutions to conventional problems, and strive to preempt unconventional dilemmas with cutting-edge ideas. We demand integrity and respect passion.":"Letter",
      "Introduce yourself to your future classmates. Here’s your chance to put a face with a name, let your personality shine through, be conversational, be yourself. We can’t wait to meet you!":"Video",
      "How has the world you come from shaped who you are today? For example, your family, culture, community, all help to shape aspects of your life experiences and perspective. Please use this opportunity to share more about your background.":"Background"
    },
    nyu: {
      "What are your short-term career goals?":"Goals",
      "In today’s global business environment, the only constant is change. Using NYU Stern’s brand call to action, we want to know how you view change. Change: _____ it. Fill in the blank with a word of your choice. Why does this word resonate with you? How will you embrace your own personal tagline while at Stern?":"Change",
      "Introduce yourself to the Admissions Committee and to your future classmates using six images and corresponding captions. The Pick Six is a way to share more about the qualities you will bring to the Stern community, beyond your professional and academic achievements.":"Six",
      "Please provide any additional information that you would like to bring to the attention of the Admissions Committee and/or give context to your application. This may include important aspects of yourself not otherwise apparent in your application, including but not limited to: hardships you have encountered, current or past gaps in employment, further explanation of your undergraduate record or self-reported academic transcript(s), plans to retake the GMAT, GRE, Executive Assessment, IELTS or TOEFL, or any other relevant information.":"Additional"
    },
    northwestern: {
      "Intentionality is a key aspect of what makes our graduates successful Kellogg leaders. Help us understand your journey by articulating your motivations for pursuing an MBA, the specific goals you aim to achieve, and why you believe now is the right moment. Moreover, share why you feel Kellogg is best suited to serve as a catalyst for your career aspirations and what you will contribute to our community of lifelong learners during your time here.":"Intentionality",
      "Kellogg leaders are primed to tackle challenges everywhere, from the boardroom to their neighborhoods. Describe a specific professional experience where you had to make a difficult decision. Reflecting on this experience, identify the values that guided your decision-making process and how it impacted your leadership style.":"Challenge",
      "Video questions":"Videos",
      "How have you grown or changed personally and professionally since you previously applied and what steps have you taken to become the strongest candidate you can be?":"Reapplicant",
      "We know that life is full of extenuating circumstances. Whether you want to explain gaps in work experience, your choice of recommenders, inconsistent or questionable academic performance or something else, you can use this section to briefly tell us anything we need to know about your application.":"Additional"
    },
    stanford: {
      "Essay A: What matters most to you, and why?":"EssayA",
      "Essay B: Why Stanford?":"EssayB",
      "Think about a time in the last few years when you’ve created a positive impact, whether in professional, extracurricular, academic, or other settings. What was your impact? What made it significant to you or to others?":"Impact"
    },
    berkeley: {
      "What makes you feel alive when you are doing it, and why?":"Alive",
      "What are your short-term and long-term career goals, and how will an MBA from Haas help you achieve those goals?":"Goals",
      "Describe any experience or exposure you have in the area of diversity, equity, inclusion, justice, and belonging whether through community organizations, personal, or in the workplace?":"DEI",
      "The Berkeley MBA program develops leaders who embody our four Defining Leadership Principles. Briefly introduce yourself to the admissions committee, explain which Defining Leadership Principle resonates most with you, and tell us how you have exemplified the principle in your personal or professional life. Please review the Defining Leadership Principles in advance and take time to prepare your answer before recording. You will be able to test your audio-visual connection before recording. Video essays should last 1-2 minutes and may not exceed 2 minutes.":"Four",
      "Optional Information #1: We invite you to help us better understand the context of your opportunities and achievements.":"Optional1",
      "Optional Information #2: This section should only be used to convey relevant information not addressed elsewhere in your application. This may include explanation of employment gaps, academic aberrations, supplemental coursework, etc. You are encouraged to use bullet points where appropriate.":"Optional2"
    },
    ucla: {
      "Why are you pursuing an MBA?":"Why",
      "Describe your short-term and long-term post-MBA career goals.":"Goals",
      "At Anderson, our mission is to prepare transformative leaders by developing students to think innovatively, problem-solve, and adapt to others – all with a driven and purposeful mindset. How do you plan to achieve your goals and develop into a transformative leader during your time at UCLA Anderson?":"Leader",
      "No preference is given in the evaluation process to those who choose to respond to this optional essay, so please use your best judgment: Are there any extenuating circumstances in your profile about which the Admissions committee should be aware?":"Optional"
    },
    unc: {
      "What are your immediate career goals and how will you benefit from earning an MBA at Kenan-Flagler Business School? As the business world continues to evolve, circumstances can change that can guide you in a different direction. Should your goals that you provided above not transpire, what other opportunities would you explore?":"Goals",
      "Reflect on a project or initiative you were involved in that had a significant positive impact on a community, organization, or individual. How did this experience shape your sense of responsibility as a future leader dedicated to making a difference in the world?":"Project",
      "We encourage you to tell us more about who you are so we can get to know you as a person and potential member of the Carolina community. Use this essay to convey information that you may not have otherwise been able to in the application.":"Optional"
    },
    chicago: {
      "How will a Booth MBA help you achieve your immediate and long-term post-MBA career goals?":"Goals",
      "An MBA is as much about personal growth as it is about professional development. In addition to sharing your experience and goals in terms of career, we’d like to learn more about you outside of the office. Use this opportunity to tell us something about who you are…":"About",
      "Is there any unclear information in your application that needs further explanation?":"Optional",
      "Upon reflection, how has your perspective regarding your future, Chicago Booth, and/or getting an MBA changed since the time of your last application?":"Reapplicant"
    },
    michigan: {
      "At Michigan Ross, we believe the best way to learn is by doing. In our Full-Time MBA Program, you will put your skills and knowledge to the test in real-world situations with real stakes. How will this learning environment benefit you, and how do you plan to contribute to the action-based learning experience?":"Contribute",
      "Michigan Ross is proud to support a community of leaders and impact makers. As a future member of this community, we want to know more about who you are and what drives you. Please choose 1 of the following prompts to tell us more about what makes you stand out beyond your academic and work experience. List the prompt you are answering at the top of your response. \n What makes you unique? \n Can you provide a specific example of how you’ve overcome a personal challenge? \n What makes you excited to get up each morning? \n Describe a time when you made a difference in your community or with an individual.":"Groups",
      "What is your short-term career goal, and how will Ross help you achieve it?":"Goals",
      "Is there something in your resume or application that needs a brief explanation? Appropriate uses of this essay would be the explanation of an employment gap, academic outliers, choice of recommender, completion of supplemental coursework, etc. You may use bullet points where appropriate.":"Optional"
    },
    penn: {
      "How do you plan to use the Wharton MBA program to help you achieve your future professional goals? You might consider your past experience, short and long-term goals, and resources available at Wharton.":"Goals",
      "Taking into consideration your background – personal, professional, and/or academic – how do you plan to make specific, meaningful contributions to the Wharton community?":"Contribute",
      "Please use this space to share with the Admissions Committee how you have reflected and grown since your previous application and discuss any relevant updates to your candidacy (e.g., changes in your professional life, additional coursework, and extracurricular/volunteer engagements).":"Reapplicant",
      "Please use this space to share any additional information about yourself that cannot be found elsewhere in your application and that you would like to share with the Admissions Committee. This space can also be used to address any extenuating circumstances (e.g., unexplained gaps in work experience, choice of recommenders, inconsistent or questionable academic performance, areas of weakness, etc.) that you would like the Admissions Committee to consider.":"Optional"
    },
    virginia: {
      "What would you want your classmates to know about you that is not on your resume?":"Resume",
      "Please describe a tangible example that illuminates your experience promoting an inclusive environment and what you would bring to creating a welcoming, global community at Darden.":"Inclusive",
      "At this time how would you describe your short-term, post-MBA goal in terms of industry, function, geography, company size and/or mission and how does it align with the long-term vision you have for your career?":"Goals"
    },
    usc: {
      "What is your specific, immediate short-term career goal upon completion of your MBA? Please include an intended position, function, and industry in your response.":"Short",
      "Please draft a letter that begins with “Dear Admissions Committee” This letter serves as your personal statement that provides the Admissions Committee with an understanding of your candidacy for Marshall beyond what is evident in other parts of your application. This essay is purposely open-ended. You are free to express yourself in whatever way you see fit. The goal is to have an appreciation for and an understanding of each candidate in ways that are not captured by test scores, grades, and resumes.":"Letter",
      "Please provide any additional information you would like the admissions committee to consider.":"Optional"
    },
    yale: {
      "Briefly describe your career interests and how you arrived at them. What have you already done to pursue these interests? What do you need to do going forward?":"Interests",
      "Describe the biggest commitment you have ever made. Why is this commitment meaningful to you and what actions have you taken to support it?":"Commitment",
      "Describe the most significant challenge you have faced. How have you confronted this challenge and how has it shaped you as a person?":"Challenge",
      "If any aspect of your application requires additional explanation, you can address it in the Optional Information section below.  Please note, you should use the specific prompts provided in the Work Experience section to address gaps in work experience or choice of recommender. The Optional Information section is truly optional – if no aspect of your application requires further explanation, you should leave this section blank.":"Optional"
    }
  };

const ChatPage = () => {
  const { school, question } = useParams();
  const decodedQuestion = decodeURIComponent(question);
  const [userInput, setUserInput] = useState('');
  const [chatMessages, setChatMessages] = useState([]);
  const bottomRef = useRef(null);
  const location = useLocation();
  const navigate = useNavigate();
  const userid = location.state?.userid;
  const [showAttached, setShowAttached] = useState(false);

  const schoolLogos = {
    columbia: Columbia,
    berkeley: Berkeley,
    chicago: Chicago,
    cornell: Cornell,
    dartmouth: Dartmouth,
    duke: Duke,
    harvard: Harvard,
    michigan: Michigan,
    mit: MIT,
    northwestern: Northwestern,
    nyu: NYU,
    penn: Penn,
    stanford: Stanford,
    ucla: UCLA,
    virginia: Virginia,
    yale: Yale,
    hec: HEC,
    insead: INSEAD,
    lbs: LBS,
    unc: UNC,
    usc: USC,
  };

  // State to manage chat session
  const [chatId, setChatId] = useState(null);
  const [loadingChat, setLoadingChat] = useState(true);
  const [chatError, setChatError] = useState(null);

  // Scroll to the bottom of the chat window whenever the chat messages are updated
  useEffect(() => {
    if (bottomRef.current) {
      bottomRef.current.scrollIntoView({ behavior: 'smooth' });
    }
  }, [chatMessages]);

  useEffect(() => {
    const initializeChat = async () => {
      if (!userid) {
        console.error('ChatPage - User ID not found.');
        return;
      }

      // Check if a chat exists for this user, school, and question
      const { data: existingChats, error: fetchError } = await supabase
        .from('chats')
        .select('*')
        .eq('user', userid)
        .eq('school', school)
        .eq('question', decodedQuestion)
        .order('most_recent_message', { ascending: false });

      if (fetchError) {
        console.error('ChatPage - Error fetching chats:', fetchError.message);
        setChatError('Error fetching chat session.');
        setLoadingChat(false);
        return;
      }

      if (existingChats && existingChats.length > 0) {
        // Use the most recent chat
        const chat = existingChats[0];
        setChatId(chat.id);

        // Fetch messages associated with this chat
        const { data: messagesData, error: messagesError } = await supabase
          .from('messages')
          .select('*')
          .in('id', chat.messages)
          .order('created_at', { ascending: true });

        if (messagesError) {
          console.error('ChatPage - Error fetching messages:', messagesError.message);
          setChatError('Error fetching messages.');
          setLoadingChat(false);
          return;
        }

        const sortedMessages = messagesData || [];
        setChatMessages(sortedMessages.map(msg => ({
          sender: msg.user_created ? 'user' : 'gpt',
          content: msg.user_created ? msg.message : formatGptResponse(msg.message),
        })));
      } else {
        // Create a new chat
        setChatId(null);
        setChatMessages([]);
      }

      setLoadingChat(false);
    };

    initializeChat();
  }, [userid, school, decodedQuestion, navigate]);

  const fetchExampleEssays = async (school, codeWord) => {
    //console.log('Fetching example essays for:', { school, codeWord });  
    const { data, error } = await supabase
      .from('questions')
      .select('*')
      .eq('school', school)
      .eq('code_word', codeWord)
      .single();
  
    if (error) {
      console.error('Error fetching example essays:', error);
      return [];
    }
  
    //console.log('Example essays fetched:', data.example_essays);
    return data.example_essays;
  };

  const fetchQuestionText = async (school, codeWord) => {
    //console.log('Fetching question text for:', { school, codeWord });
  
    const { data, error } = await supabase
      .from('questions')
      .select('question')
      .eq('school', school)
      .eq('code_word', codeWord)
      .single();
    //console.log('Supabase response data (question):', data);
    //console.log('Supabase response error:', error);
    if (error) {
      console.error('Error fetching question text:', error);
      return '';
    }
    //console.log('Question text fetched:', data.question);
    return data.question;
  };

  const handleSendMessage = async () => {
    if (!userInput.trim()) return;
    setShowAttached(false);
    
    // If no chat exists yet, create one
    let currentChatId = chatId;
    if (!currentChatId) {
      currentChatId = uuidv4();
      const currentTime = new Date().toISOString();

      const { error: createError } = await supabase
        .from('chats')
        .insert({
          id: currentChatId,
          user: userid,
          most_recent_message: currentTime,
          school: school || null,
          question: decodedQuestion || null,
          question_code: questionCodes[school][decodedQuestion] || null,
          messages: [],
          title: school.toUpperCase() + ': ' + questionCodes[school][decodedQuestion],
        })
        .single();

      if (createError) {
        console.error('ChatPage - Error creating new chat:', createError.message);
        alert('Error creating chat session. Please try again.');
        return;
      }

      setChatId(currentChatId);
      // console.log('ChatPage - New chat created:', newChat);
    }

    // Insert user message into messages table
    const userMessageId = uuidv4();
    const userMessage = {
      id: userMessageId,
      created_at: new Date().toISOString(),
      user: userid,
      user_created: true,
      message: userInput.trim(),
    };

    const { error: insertUserMsgError } = await supabase
      .from('messages')
      .insert(userMessage);

    if (insertUserMsgError) {
      console.error('ChatPage - Error inserting user message:', insertUserMsgError.message);
      alert('Failed to send message. Please try again.');
      return;
    }

    // Update chats table: add message ID to messages array and update timestamp
    const { data: currentChat, error: fetchChatError } = await supabase
      .from('chats')
      .select('messages')
      .eq('id', currentChatId)
      .single();

    if (fetchChatError) {
      console.error('ChatPage - Error fetching current chat:', fetchChatError.message);
      alert('Failed to update chat. Please try again.');
      return;
    }

    const updatedMessages = [...currentChat.messages, userMessageId];

    const { error: updateChatError } = await supabase
      .from('chats')
      .update({
        messages: updatedMessages,
        most_recent_message: new Date().toISOString(),
      })
      .eq('id', currentChatId);

    if (updateChatError) {
      console.error('ChatPage - Error updating chat with user message:', updateChatError.message);
      alert('Failed to update chat. Please try again.');
      return;
    }

    // Add user message to state
    setChatMessages(prevMessages => [...prevMessages, { sender: 'user', content: userInput.trim() }]);
    setUserInput('');

    // Now, generate the AI response
    await handleGenerateOutput(userMessage, currentChatId);
  };
  
  const handleGenerateOutput = async (userMessage, currentChatId) => {
    setUserInput('');
    const textarea = document.querySelector('.user-input');
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = '32px';
    }

    const previousMessages = chatMessages.map(msg => {
      const sender = msg.sender === 'user' ? 'User' : 'GPT';
      return `${sender}: ${msg.content}`;
    }).join('\n\n');

    const codeWord = questionCodes[school][decodedQuestion];
    const examples = await fetchExampleEssays(school, codeWord);
    const questionText = await fetchQuestionText(school, codeWord);
  
    if (!examples.length) {
      console.error('No example essays found.');
      return;
    }

    //Fetch attachments
    const { data: currentChat } = await supabase
      .from('chats')
      .select('attachments')
      .eq('id', currentChatId)
      .single();

    // Function to read file contents
    const getFileContents = async (filePath, fileType) => {
      try {
        // Download file from Supabase Storage
        const { data, error } = await supabase.storage
          .from('attachments')
          .download(filePath);
        
        if (error) throw error;

        // Parse based on file type
        if (fileType === 'application/pdf') {
          const arrayBuffer = await data.arrayBuffer();
          const pdf = await pdfjsLib.getDocument({ data: arrayBuffer }).promise;
          let fullText = '';
          
          // Get text from each page
          for (let i = 1; i <= pdf.numPages; i++) {
            const page = await pdf.getPage(i);
            const textContent = await page.getTextContent();
            const pageText = textContent.items.map(item => item.str).join(' ');
            fullText += pageText + '\n';
          }
          
          return fullText;
        } else if (fileType.includes('word')) {
          const result = await mammoth.extractRawText({ arrayBuffer: await data.arrayBuffer() });
          return result.value;
        }
        
        return 'Unsupported file type';
      } catch (error) {
        console.error('Error reading file:', error);
        return `Error reading file: ${error.message}`;
      }
    };
    // Get contents of all attachments
    let attachmentsContent = 'No attachments';
    if (currentChat?.attachments?.length > 0) {
      const attachmentsWithContent = await Promise.all(
        currentChat.attachments.map(async (att) => {
          const content = await getFileContents(att.file_path, att.file_type);
          return `File: ${att.file_name}\nContent:\n${content}\n-------------------`;
        })
      );
      attachmentsContent = attachmentsWithContent.join('\n\n');
    }
  
    const prompt = `
    You are an expert MBA essay consultant.
    Your goal is to assist clients in creating their essays by using their prompt along with the high-quality example essays provided to help with the process, use those as your guide. At the end, you may ask clarifying questions about any assumptions, provide constructive feedback, and guide them toward the best possible output using the available data. UNDER NO CIRCUMSTANCES should you output any specific content from the example essays; they should only be used as a guide for previous successful essays.

    Guiding Principles
    Natural Consulting Flow:
    Maintain a conversational and consultative approach throughout the interaction. Engage with clients as a consultant would, ensuring the dialogue feels natural and supportive.

    Comprehensive Information Gathering:
    Do not generate the final version of the essay until you are confident you have all the information needed to create a high-quality essay. Ensure thorough understanding by asking questions and clarifying all aspects of the client's needs and objectives to ensure it meets the high quality of the example essays.

    Be Specific:
    The more criteria you give, the more focused the output will be. Encourage clients to provide detailed information and specific examples to enhance the quality of the essay.

    Work in Steps:
    Break tasks into small, manageable chunks for better results. Address each section of the essay methodically to ensure comprehensive coverage.

    Iterate and Improve:
    Continuously refine inputs and improve outputs through iterative feedback and revisions. Encourage clients to view the essay development as an evolving process and use the example essays as a guide for feedback, but under no circumstances mention any identifying or specific infomratino about them.

    Main Instructions
    Clarifying Assumptions:
    Identify and question any assumptions in the essay to ensure clarity and precision. Use probing questions to understand the context and the client’s intent behind certain statements or arguments.

    Example Questions:
    "Can you explain what you mean by this statement?"
    "What evidence supports this assumption?"
    "How does this point relate to your main argument?"
    Providing Constructive Feedback:
    Critique the structure, coherence, and logical flow of the essay. Highlight strengths and suggest improvements for weaknesses. Focus on clarity, conciseness, and relevance.

    Example Feedback:
    "This paragraph provides a strong argument, but it would benefit from more concrete examples."
    "Consider reorganizing these sections to enhance the logical flow of your essay."
    Guide Towards the Best Output:
    Offer specific suggestions for revision. Provide examples or templates if needed. Encourage iterative improvements by reviewing subsequent drafts.

    Example Guidance:
    "Here is a revised version of your thesis statement for better clarity."
    "Try using this structure for your introduction to make it more engaging."
    Iterative Improvement:
    Encourage the client to make revisions based on feedback. Offer to review revised drafts and provide additional feedback. Repeat the process until the client is satisfied with the essay.

    Process Flow
    Initial Consultation:
    Begin with a generated essay to set the stage. Engage the client with questions to understand their goals, audience, and key points they wish to convey.

    Information Gathering:
    Ensure all necessary information is collected before proceeding. Ask clarifying questions and request additional details as needed.

    Draft Development:
    Work collaboratively with the client to develop each section of the essay. Provide feedback and suggestions at each stage.

    Review and Refine:
    Continuously review drafts, offering constructive feedback and guiding revisions to enhance the essay's quality.

    Finalization:
    Once confident that all information is gathered and the essay meets high-quality standards, assist the client in generating the final version.

    Example Interaction
    Client:
    I have written an essay on climate change, but I feel it lacks impact. Can you help me improve it?

    Consultant (You):
    Of course! Let's start by discussing the purpose of your essay and the audience you're targeting. Could you also share your draft with me so I can understand where improvements might be needed?

    Client:
    The purpose is to raise awareness about the urgency of climate change, and the audience is the general public. [Shares draft]

    Consultant (You):
    Thank you for sharing your draft. I noticed in your introduction you state, "Climate change is the most pressing issue of our time." What evidence can you provide to support this assertion? Additionally, your second paragraph discusses greenhouse gases but lacks specific examples. Including data on CO₂ emissions could strengthen your argument. Let’s work on revising your thesis statement to make it clearer and more impactful. What specific points would you like to emphasize in your thesis?

    Question: ${questionText}  

    Example Essays:
    ${examples.join('\n\n')}
    
    Previous chats from this thread in chronological order beginning with the first message every sent, refer to them for context on the user input:
    ${previousMessages}

    Attachments:
    ${attachmentsContent}

    User Input:
    ${userMessage.message}    
    `;

    try {
      const response = await fetch('https://chatsbc.adam-74c.workers.dev/openai', { // Update this URL based on your backend setup
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ prompt }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.error || 'Backend error');
      }
  
      // Handle the streaming response
      const reader = response.body.getReader();
      const decoder = new TextDecoder('utf-8');
      let buffer = '';
      let generatedMessage = '';

      while (true) {
        const { done, value } = await reader.read();
        if (done) break;

        buffer += decoder.decode(value, { stream: true });

        // Split by lines
        const lines = buffer.split('\n');
        buffer = lines.pop(); // Save the last partial line

        for (const line of lines) {
          const trimmedLine = line.trim();
          if (trimmedLine.startsWith('data: ')) {
            const dataStr = trimmedLine.replace(/^data: /, '');
            if (dataStr === '[DONE]') {
              break;
            }
            try {
              const data = JSON.parse(dataStr);
              const content = data.choices[0].delta.content;
              if (content) {
                generatedMessage += content;
                const formattedResponse = formatGptResponse(generatedMessage);

                setChatMessages(prevMessages => {
                  const updatedMessages = [...prevMessages];
                  const lastMessageIndex = updatedMessages.length - 1;
                  if (updatedMessages[lastMessageIndex]?.sender === 'gpt') {
                    updatedMessages[lastMessageIndex].content = formattedResponse;
                  } else {
                    updatedMessages.push({ sender: 'gpt', content: formattedResponse });
                  }

                  return updatedMessages;
                });
              }
            } catch (e) {
              console.error('Error parsing JSON:', e);
            }
          }
        }
      }

      const messageId = uuidv4();
      const message = {
        id: messageId,
        created_at: new Date().toISOString(),
        user: userid,
        user_created: false,
        message: generatedMessage,
      };
      const { error: insertMsgError } = await supabase
        .from('messages')
        .insert(message);

      if (insertMsgError) {
        console.error('ChatPage - Error inserting AI message:', insertMsgError.message);
        return;
      }

      const { data: currentChat, error: fetchChatError } = await supabase
        .from('chats')
        .select('messages')
        .eq('id', currentChatId)
        .single()

      if (fetchChatError) {
        console.error('ChatPage - Erorr fetching current chat:', fetchChatError.message);
        return;
      }

      const updatedMessages = [...currentChat.messages, messageId];
      const { error: updateChatError } = await supabase
        .from('chats')
        .update({
          messages: updatedMessages,
          most_recent_message: new Date().toISOString(),
        })
        .eq('id', currentChatId);

      if (updateChatError) {
        console.error('ChatPage - Error updating chat with AI message:', updateChatError.message);
        return;
      }
    } catch (error) {
      console.error("Error generating output:", error);
    }
  };

  const handleAttachFile = async () => {
    // First ensure we have a valid chatId
    let currentChatId = chatId;
    if (!currentChatId) {
      // Create a new chat if one doesn't exist
      currentChatId = uuidv4();
      const currentTime = new Date().toISOString();
      
      const { error: createError } = await supabase
        .from('chats')
        .insert({
          id: currentChatId,
          user: userid,
          most_recent_message: currentTime,
          school: school || null,
          question: decodedQuestion || null,
          question_code: questionCodes[school][decodedQuestion] || null,
          messages: [],
          attachments: [],
          title: school.toUpperCase() + ': ' + questionCodes[school][decodedQuestion],
        })
        .single();

      if (createError) {
        console.error('Error creating new chat:', createError);
        alert('Error creating chat session. Please try again.');
        return;
      }
      
      setChatId(currentChatId);
    }

    // Create file input element
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = '.pdf,.doc,.docx';
    
    input.onchange = async (e) => {
      const file = e.target.files[0];
      if (!file) return;
      
      // Validate file type
      const validTypes = ['application/pdf', 'application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'];
      if (!validTypes.includes(file.type)) {
        alert('Please upload only PDF or Word documents');
        return;
      }
      
      try {
        // Show loading state
        // alert('Uploading file...');
        
        // Create a unique filename to prevent collisions
        const uniqueFileName = `${userid}/${currentChatId}/${Date.now()}-${file.name}`;
        
        // Upload file to Supabase Storage
        const { error: uploadError } = await supabase.storage
          .from('attachments')
          .upload(uniqueFileName, file);
          
        if (uploadError) throw uploadError;

        // Get the public URL for the uploaded file
        const { data: { publicUrl } } = supabase.storage
          .from('attachments')
          .getPublicUrl(uniqueFileName);
          
        // Fetch current attachments array
        const { data: currentChat, error: fetchError } = await supabase
          .from('chats')
          .select('attachments')
          .eq('id', currentChatId)
          .single();
          
        if (fetchError) throw fetchError;
          
        // Prepare new attachment metadata
        const newAttachment = {
          file_name: file.name,
          file_path: uniqueFileName,
          file_type: file.type,
          file_size: file.size,
          uploaded_at: new Date().toISOString(),
          public_url: publicUrl
        };
          
        // Create new attachments array with existing attachments (if any)
        const updatedAttachments = [
          ...(currentChat?.attachments || []),
          newAttachment
        ];
          
        // Update the chat record with new attachments array
        const { error: updateError } = await supabase
          .from('chats')
          .update({ attachments: updatedAttachments })
          .eq('id', currentChatId);
          
        if (updateError) throw updateError;
          
        setShowAttached(true);
          
      } catch (error) {
        console.error('Error handling file:', error);
        alert('Error uploading file. Please try again.');
      }
    };
    
    // Trigger file selection
    input.click();
  };
  
  return (
    <div className="chat-page">
      <Banner userid={userid} /> 
      <div className="chat-window">
      <div className="logo-and-question">
        <img src={schoolLogos[school]} alt={school} className="school-logo" />
        <h2 className="question-text">{decodedQuestion}</h2>
      </div>
        {loadingChat && <div>Loading chat...</div>}
        {chatError && <div>Error: {chatError}</div>}
        {chatMessages.map((message, index) => (
          <div
            key={index}
            className={`chat-message ${message.sender}`}
            dangerouslySetInnerHTML={{ __html: message.content }}
          ></div>
        ))}
        <div ref={bottomRef}></div> 
      </div>
      <div className="chat-input-container">
        <button className="paperclip" onClick={handleAttachFile}>
          <img src={showAttached ? Attached : Paperclip} alt="Attach" />
        </button>
        <textarea
          className="user-input"
          placeholder="Enter your input here..."
          value={userInput}
          onChange={(e) => setUserInput(e.target.value)}
          onInput={(e) => {
            e.target.style.height = "auto";
            e.target.style.height = (e.target.scrollHeight - 10) + "px";  
          }}
          onKeyDown={(e) => {
            if (e.key === 'Enter' && !e.shiftKey) {
              e.preventDefault();
              handleSendMessage();
            }
          }}
        />
        <button className="generate-button" onClick={handleSendMessage}>
          <img src={SubmitIcon} alt="Submit" />
        </button>
      </div>
    </div>
  );
};

export default ChatPage;