import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/MIT.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "MIT Sloan seeks students whose personal characteristics demonstrate that they will make the most of the incredible opportunities at MIT, both academic and non-academic. We are on a quest to find those whose presence will enhance the experience of other students. We seek thoughtful leaders with exceptional intellectual abilities and the drive and determination to put their stamp on the world. We welcome people who are independent, authentic, and fearlessly creative — true doers. We want people who can redefine solutions to conventional problems, and strive to preempt unconventional dilemmas with cutting-edge ideas. We demand integrity and respect passion."
  },
  {
    text: "Introduce yourself to your future classmates. Here’s your chance to put a face with a name, let your personality shine through, be conversational, be yourself. We can’t wait to meet you!"
  },
  {
    text: "How has the world you come from shaped who you are today? For example, your family, culture, community, all help to shape aspects of your life experiences and perspective. Please use this opportunity to share more about your background."
  }
]

const MIT = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/mit/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="MIT Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default MIT;