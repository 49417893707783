import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import './Questions.css';
import Logo from '../Assets/Michigan.jpg';
import Banner from '../Banner.js';

const questions = [
  {
    text: "At Michigan Ross, we believe the best way to learn is by doing. In our Full-Time MBA Program, you will put your skills and knowledge to the test in real-world situations with real stakes. How will this learning environment benefit you, and how do you plan to contribute to the action-based learning experience?"
  },
  {
    text: "Michigan Ross is proud to support a community of leaders and impact makers. As a future member of this community, we want to know more about who you are and what drives you. Please choose 1 of the following prompts to tell us more about what makes you stand out beyond your academic and work experience. List the prompt you are answering at the top of your response. \n What makes you unique? \n Can you provide a specific example of how you’ve overcome a personal challenge? \n What makes you excited to get up each morning? \n Describe a time when you made a difference in your community or with an individual."
  },
  {
    text: "What is your short-term career goal, and how will Ross help you achieve it?"
  },
  {
    text: "Is there something in your resume or application that needs a brief explanation? Appropriate uses of this essay would be the explanation of an employment gap, academic outliers, choice of recommender, completion of supplemental coursework, etc. You may use bullet points where appropriate."
  }
]

const Michigan = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const userid = location.state?.userid;

  const handleQuestionClick = (question) => {
    navigate(`/chat/michigan/${encodeURIComponent(question)}`, { state: { userid } });
  };

  return (
    <div className="main-container">
      <Banner userid={userid} /> 
      <div className="questions-grid">
        <div className="logo-container">
          <img src={Logo} alt="Michigan Logo" className="school-logo" />
          <h2 className="question-header">Select a question:</h2>
        </div>
        {questions.map((question, index) => (
          <button key={index} className="question-button" onClick={() => handleQuestionClick(question.text)}>
            {question.text}
          </button>
        ))}
      </div>
    </div>
  );
};

export default Michigan;